.editable-select.form-select {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.editable-select.form-select:focus {

  outline: none;
  box-shadow: none;
  border-color: lightgray;
}

.test-focus:focus{
  outline: 4px black
}

.editable-select.form-control {
  width: 100%;
}

.editable-select.btn {
  background-color: white;
  border-color: lightgray;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
  padding-inline: 3px; 
}

.editable-select.btn:disabled {
  background-color: white;
}