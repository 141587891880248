.tile-shadow {
box-shadow: 1px 1px 1px 0px lightgray;
padding: 10px;
margin-bottom: 10px;
border-radius: 3px;

}

.tile-shadow:hover{
  box-shadow: 1px 1px 1px 1px lightgray;
  }

.tile-project-name {
  cursor: pointer;
}

.tile-delete:hover {
  background-color: #b80000 !important;
}