.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,
html,
#root,
#app
{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
  font-size: 12px;
  height: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  color:#3c434a;
}

.myForm {
  min-width: 300px;
  position: absolute;
  top: 20%;
  left: 50%;
  padding: auto;
  background-color: lightblue;
  transform: translate(-50%, -50%);
}
@media (max-width: 500px) {
  .myForm {
    min-width: 90%;
  }
}

.app-content {
  min-height:95%;
}

.delete.btn-outline-secondary:hover, .delete.btn-secondary:hover {
  background-color: #b80000;
}

.landing.first {
  background-color: rgb(222, 222, 228);
}

.landing img {
  margin-top: 70px;
  margin-bottom: 70px;
  border: 1px solid lightgray; 
}

.landing.last {
  background-color: rgb(53, 69, 96);
  padding-top: 60px;
  padding-bottom: 60px;
  color: white;
}

.landing.middle {
 max-width: 1000px;
 margin:0 auto;
}

.landing h1 {
  font-size: 40px;
  margin-bottom: 30px;
  padding-inline: 12px;
}

.landing p {
  font-size: 20px;
  margin-bottom: 20px;
  padding-inline: 12px;
}


.landing button {
  margin-top: 16px;
  font-size: 14px;
  padding: 10px 20px;
  margin: 12px;
  background-color:rgb(53, 69, 96);
  border-color: rgb(53, 69, 96);
  
}

.landing-card {
  background-color: rgb(60, 81, 117);
  margin: 30px 30px;
  padding: 20px 40px;
  border-radius: 10px;
  min-height: 200px;
}

.landing-card p {
  font-size: 18px;
  padding-top: 15px;
}

