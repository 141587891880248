.level4.list-group-item {
  background-color: rgba(255, 250, 205, 0.5);
}

.level3.list-group-item {
  background-color: rgba(173, 216, 230, 0.5);
}

.level2.list-group-item {
  background-color: rgba(240, 128, 128, 0.5);
}