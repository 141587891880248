* {
  box-sizing: border-box;
}
body,
html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
}
a:link {
  text-decoration: none;
}
.content {
  position: relative;
  width: 100%;
  padding:0;
  height: 100%;
  display: flex;
}
.content > .message {
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
  font-size: 16px;
  color: #111;
}
.content > .message .note {
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.content > .message.error .details {
  max-width: 500px;
  font-size: 12px;
  margin: 20px auto;
  text-align: left;
  color: #BD2828;
}
.content > .message.error pre {
  border: solid 1px #BD2828;
  background: #fefafa;
  padding: 10px;
  color: #BD2828;
}
.content:not(.with-error) .error,
.content.with-error .intro,
.content.with-diagram .intro {
  display: none;
}
.content .canvas {
  width: 100%;
}
.content .canvas,
.content .properties-panel-parent {
  display: none;
}
.content.with-diagram .canvas,
.content.with-diagram .properties-panel-parent {
  display: block;
}
.buttons {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.buttons > li {
  display: inline-block;
  margin-right: 10px;
}
.buttons > li > a {
  background: #DDD;
  border: solid 1px #666;
  display: inline-block;
  padding: 5px;
}
.buttons a {
  opacity: 0.3;
}
.buttons a.active {
  opacity: 1;
}
.properties-panel-parent {
  border-left: 1px solid #ccc;
  overflow: auto;
}
.properties-panel-parent:empty {
  display: none;
}
.properties-panel-parent > .djs-properties-panel {
  padding-bottom: 70px;
  min-height: 100%;
}

input#camunda-id{
  pointer-events: none;
  padding-right: 6px;
  opacity: 0.60;
}

button.action-button.clear {
  display: none;
}


input#camunda-participant-id{
  pointer-events: none;
  padding-right: 6px;
  opacity: 0.60;
}

input#bio-properties-panel-id{
  pointer-events: none;
  padding-right: 6px;
  opacity: 0.60;
}

.disabled-participant-name #bio-properties-panel-name{
  pointer-events: none;
  padding-right: 6px;
  opacity: 0.60;
}

button.action-button.clear {
  display: none;
}

