.diagram-menu {
  position: relative;
  width: 100%;
  height: 39px;
  padding-top: 5px ;
  display:flex;  
}

.diagram-menu.level3{
  background-color: rgb(173, 216, 230, 0.5);
}

.diagram-menu.level4{
  background-color: rgb(255, 250, 205, 0.5);
}

.diagram-menu.level2{
  background-color: rgb(240, 128, 128, 0.5);
}

.diagram-content
{
  position: relative;
  width: 100%;
  height: 100%;
}

.breadcrumb-item {
  --bs-breadcrumb-divider: "▶";
  display: flex;
  align-items: center;
  font-weight: bolder;
}

.breadcrumb {
  margin-bottom: 0;
}

li.breadcrumb-item {
  padding-inline: 2px;
}

